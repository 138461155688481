.slider {
  position: relative;
  text-align: center;

  .slider__inner {
    width: 100vw;
    height: 75vh;
  }

  .slick-list,
  .slick-track,
  .slick-slide,
  .slick-slide > div {
    height: 100%;
  }

  &--full-height {
    .slider__inner {
      width: 100vw;
      height: 100vh;
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    picture {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__video {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    iframe,
    video {
      position: absolute;
    }
  }

  &__caption {
    position: absolute;
    top: 40%;
    left: 40px;
    right: 40px;
    color: #fff;
    z-index: 1;

    h2 {
      margin-bottom: 18px;
      font-size: 47px;

      @include breakpoint(small only) {
        margin-bottom: 8px;
      }
    }

    p {
      color: get-color("white");
      font-size: 13px;
    }

    a {
      &:hover,
      &:active,
      &:focus {
        border: 1px solid get-color("grey");
        background: get-color("snow-drift");
        color: get-color("marble-blue");
      }
    }
  }
}
