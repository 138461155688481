.offers {
  width: 100%;
  max-width: 1344px;
  margin: 0 auto;
  padding: 0 42px 42px;

  @include breakpoint(small only) {
    padding: 0 18px 36px;
  }

  &__grid {
    position: relative;

    @include breakpoint(small only) {
      padding: 0 0 36px;
    }
  }

  &__item {
    margin-bottom: 105px;

    @include breakpoint(medium only) {
      margin-bottom: 63px;
    }

    @include breakpoint(small only) {
      margin-bottom: 18px;
    }

    &-content {
      padding: 36px 0;

      @include breakpoint(medium only) {
        padding: 21px;
      }

      @include breakpoint(large) {
        padding: 42px;
      }

      h2 {
        @include module-panel-heading;
        color: get-color("dark-blue");
        margin-bottom: 27px;

        a {
          color: inherit;
        }

        @include breakpoint(small only) {
          margin-bottom: 18px;
        }
      }

      p {
        @include body-copy();
      }

      ul {
        @include bullet-list();
        color: get-color("dark-grey");
        list-style: none;
        margin-left: 12px;

        li {
          @include bullet-list;
          line-height: 1 !important;
          margin-bottom: 18px;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }

    &-description {
      margin-bottom: 18px;

      @include breakpoint(medium) {
        padding-right: 40px;
        margin-bottom: 0;
      }

      > :last-child {
        margin-bottom: 0 !important;
      }
    }

    &-price {
      @include breakpoint(small only) {
        padding-bottom: 18px;
      }

      @include breakpoint(medium) {
        padding-top: 10px;
      }

      h3 {
        @include price-accordion-heading;
        color: get-color("dark-blue");
      }
    }

    .multiple-buttons {
      @include breakpoint(medium) {
        display: flex;
        flex-direction: column;
        margin-left: 0;

        @include breakpoint(medium only) {
          padding-left: 0;
        }

        .button {
          display: block;
          margin: 20px 0 0;
          text-align: left;
          white-space: nowrap;

          &:first-child {
            margin-top: auto;
          }
        }
      }

      @include breakpoint(small only) {
        margin-top: 30px;
      }
    }

    &-image {
      position: relative;
      min-height: 223px;

      @include breakpoint(medium) {
        a,
        picture {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
